import React, { Component } from "react";

export default class Navbar extends Component {
  render() {
    return (
      <>
        <nav
          className="container-xxl bd-gutter flex-wrap "
          aria-label="Main navigation"
        >
          <a
            className="navbar-brand p-0 me-2 brand-logo"
            href="/"
            aria-label="Atrowel"
          >
            Atrowel
          </a>

          <button
            className="navbar-toggler collapsed ms-md-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#bdNavbar"
            aria-controls="bdNavbar"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="row align-self-start navbar-collapse collapse px-2" id="bdNavbar">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3  mb-2">
              <ul className="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0 nav-list">
                <li className="nav-item col-12 ">
                  <h6 className="dropdown-header mt-2">Image</h6>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link " href="/crop-image/">
                  Image Crop
                  </a>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link pt-0" href="/resize-image/">
                  Image Resize
                  </a>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link pt-0" href="/compress-image/">
                  Image Compress
                  </a>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link pt-0" href="/convert-to-jpg/">
                  Image Convert
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3  mb-2">
              <ul className="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0 nav-list">
                <li className="nav-item col-12 ">
                  <h6 className="dropdown-header mt-2">Json</h6>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link " href="/json-formatter/">
                  Json Formatter
                  </a>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link pt-0" href="/json-compare/">
                  Json Compare
                  </a>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link pt-0" href="/json-minifier/">
                  Json Minifier
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3  mb-2">
              <ul className="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0 nav-list">
                <li className="nav-item col-12 ">
                  <h6 className="dropdown-header mt-2">Tutorials</h6>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link " href="/introduction-to-java-programming/">
                    Core Java Tutorial
                  </a>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link pt-0" href="/javascript-tutorial/">
                  JavaScript Tutorial
                  </a>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link pt-0" href="/json-introduction/">
                  Json Tutorial
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3  mb-2">
              <ul className="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0 nav-list">
                <li className="nav-item col-12 ">
                  <h6 className="dropdown-header mt-2">Tool</h6>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link " href="/diff-compare-merge/">
                  File Compare
                  </a>
                </li>
                <li className="nav-item col-12 ">
                  <a className="nav-link pt-0" href="/character-count-online/">
                  Character Counter
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}
