import React, { Component } from 'react'

export default class PageNotFound extends Component {
  render() {
    return (
      <div>
        <div className="album py-5 ">
          <section className="py-5 text-center container mt-5 mb-5">
            <div className="row py-lg-5">
              <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="brand-logo-2 fw-light ">Page not found</h1>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
