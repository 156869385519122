import React, { Component } from "react";

export default class CookiesPopUp extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    setTimeout(async () => {
      var cookiesAccepted = await this.getCookie("acceptAtrowelCookies");
      if (!cookiesAccepted) {
        var cookieAlert = document.querySelector(".cookiealert");
        cookieAlert.classList.add("show");
      }
    }, 5000);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  setCookie = () => {
    var date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + date.toUTCString();
    document.cookie =
      "acceptAtrowelCookies" + "=" + true + ";" + expires + ";path=/";

    var cookieAlert = document.querySelector(".cookiealert");
    cookieAlert.classList.remove("show");
    window.dispatchEvent(new Event("cookieAlertAccept"));
  };

  render() {
    return (
      <div>
        <div className="alert text-center cookiealert" role="alert">
          &#x1F36A; We use cookies to enhance your browsing experience and
          analyze our traffic. By using our website, you acknowledge that you
          have read and understood our{" "}
          <a
            href="/cookies/"
            target="_blank" rel="noopener noreferrer"
            className="text-herf linkwhite"
          >
            <b>Cookie Policy</b>
          </a>{" "}
          &{" "}
          <a
            href="/privacy/"
            target="_blank" rel="noopener noreferrer"
            className="text-herf linkwhite"
          >
            <b>Privacy Policy.</b>
          </a>
          <button
            type="button"
            className="btn btn-light btn-sm acceptAtrowelCookies ms-4"
            onClick={this.setCookie}
          >
            Got It!
          </button>
        </div>
      </div>
    );
  }
}
