import React, { Component } from "react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import CookiesPopUp from "./atrowel/CookiesPopUp";
import PageNotFound from "./components/PageNotFound";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as pi from "./constants/PageInfo";

// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "bootstrap/dist/js/bootstrap.min.js";


export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isLoading: true,
    };
  }

  render() {
    const routeComponents = pi.piElementsMeta.map(({ path, element }, key) => (
      <Route exact path={path} element={element} key={key} />
    ));

    return (
      <div>
        {/* <header className="navbar navbar-expand-lg bd-navbar sticky-top shadow-app bg-white"> */}
        <header className="shadow-sm navbar bd-navbar border-bottom ">
          <NavBar></NavBar>
        </header>
        <main>
          <div className="bd-masthead" id="content">
            {/* <div className="wave-bg">
              <img className="w-100" src="./img/svg/bg-hero.svg"></img>
            </div> */}
            <BrowserRouter>
              <Routes>
                {routeComponents}
                <Route path="*" element={<PageNotFound/>}></Route>
              </Routes>
            </BrowserRouter>
          </div>
        </main>
        <footer className="shadow-lg mt-5 bd-footer py-4 py-md-5 border-top">
          <Footer></Footer>
        </footer>
        <CookiesPopUp></CookiesPopUp>
      </div>
    );
  }
}