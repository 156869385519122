 const ON_DEV       = true;

// Google Ads Details
 const AD_SENSE_ACCOUNT = "ca-pub-9722186104489791";
 const AD_SENSE_HORIZONTAL_SLOT = "5613945258";
 const AD_SENSE_VERTICAL_SLOT   = "4665531954";
 const AD_SENSE_ARTICAL_SLOT    = "9260233147";

// Meta Image Info
//  const OPENGRAPH_IMG = "https://www.atrowel.com/atrowel-meta-icon.jpg";
//  const OPENGRAPH_WIDTH = "512";
//  const OPENGRAPH_HIGHT = "512";
 const OPENGRAPH_IMG = "https://www.atrowel.com/atrowel-banner.png";
 const OPENGRAPH_WIDTH = "968";
 const OPENGRAPH_HIGHT = "225";

 const URL_INDEX = "/";

//Page URL
 const pageurl = {
    home : "/",
    about : "/about",
    contact : "/contact",
    donate : "/donate",
    privacy : "/privacy",
    terms : "/terms",
    cookies : "/cookies",
    disclaimer : "/disclaimer",
    // getSS : "/getSS",
    jsonIntroduction : "/json-introduction",
    writeJSONInJAVA : "/write-and-parse-json-data-in-java",
    writeJSONInCpp : "/write-and-parse-json-data-in-cpp",
    writeJSONInC : "/write-and-parse-json-data-in-c",
    writeJSONInPython : "/write-and-parse-json-data-in-python",
    jsonFormatter : "/json-formatter",
    jsonCompare : "/json-compare",
    jsonMinifier : "/json-minifier",
    jsonToString : "/json-to-string",
    stringToJson : "/string-to-json",
    jsonToXML : "/json-to-xml",
    xmlToJSON : "/xml-to-json",
    jsonToCSV : "/json-to-csv",
    csvToJSON : "/csv-to-json",
    cropImg : "/crop-image",
    rotateImg : "/rotate-image",
    flipImg : "/flip-image",
    resizeImg : "/resize-image",
    compressImg : "/compress-image",
    convertJPG : "/convert-to-jpg",
    convertPNG : "/convert-to-png",
    convertBMP : "/convert-to-bmp",
    convertWEBP : "/convert-to-webp",
    fileDiffCompare : "/diff-compare-merge",
    characterCounter : "/character-count-online",
    xmlformatter : "/xml-formatter",
    xmlminifier : "/xml-minifier",
    xmlcompare : "/xml-compare",
    xmlescape : "/xml-escape",
    xmlunescape : "/xml-unescape",
    // xmltocsv : "/xml-to-csv",
    // xmltotext : "/xml-to-text",
    commandLineIntro : "/linux-command-line",
    commandLineTerminal : "/access-linux-terminal",
    javaIntro : "/introduction-to-java-programming",
    javaEnvSetup : "/java-environment-setup",
    javaJShell : "/java-jshell-tool",
    javaDataTypes : "/java-data-types",
    javaNonPrimitive : "/java-non-primitive-data-types",
    javaVariables : "/variables-in-java",
    javaOperators : "/operators-in-java",
    javaBitwiseOperators : "/bitwise-operators-in-java",
    javaString : "/strings-in-java",
    javaStringMethod1 : "/string-methods-in-java",
    javaStringMethod2 : "/string-methods-in-java-part-2",
    javaStringBuffer : "/stringbuffer-class-in-java",
    javaInputOutput : "/input-and-output-in-java",
    javaControlFlow : "/control-flow-in-java",
    javaLoops : "/loops-in-java",
    javaArray : "/arrays-in-java",
    javaObjectClass : "/objects-and-classes-in-java",
    javaPredefinedClass : "/predefined-classes-in-java",
    javaDefinedOwnClass : "/defining-own-classes-in-java",
    javaStaticMethod : "/static-methods-and-variables-in-java",
    javaObjectConstruction : "/object-construction-and-destruction-in-java",
    javaPackages : "/introduction-to-packages",
    javaJAR : "/jar-files",
    javaInheritance : "/inheritance-in-java",
    javaInheritanceTypes : "/types-of-inheritance-in-java",
    javaFinalKeyword : "/final-keyword-in-java",
    javaAbstractKeyword : "/abstract-class-in-java",
    javaObject : "/object-class-in-java",
    javaArrayList : "/arraylist-in-java",
    javaWrapperClass : "/wrapper-classes-in-java",
    javaReflection : "/reflection-in-java",
    javaReflectionPar2 : "/reflection-in-java-part-2",
    javaInterface : "/interface-in-java",
    javaInterfacePart2 : "/interface-in-java-part-2",
    javaLambdaExpressions : "/lambda-expressions-in-java",
    javaLambdaExpressionsPart2 : "/lambda-expressions-in-java-part-2",
    javaAdvantagesOfLambdaExpression : "/advantages-of-lambda-expression",
    javaInnerClasses : "/inner-classes-in-java",
    javaException : "/exceptions-in-java",
    javaExceptionHandling : "/exception-handling-in-java",
    javaTypesOfException : "/types-of-exceptions-in-java",
    javaAssertion : "/assertion-in-java",
    javaCollection : "/collection-in-java",
    javaLinkedList : "/linked-list-in-java",
    javaHashSet : "/hashset-in-java",
    javaTreeSet : "/treeset-in-java",
    javaQueue : "/queue-interface-in-java",
    javaDequeue : "/dequeue-interface-in-java",
    javaArrayDeque : "/arraydeque-in-java",
    javaMap : "/map-interface-in-java",
    javaTreeMap : "/treemap-in-java",
    javaSortedMap : "/sortedmap-in-java",
    javaNavigableMap : "/navigablemap-interface-in-java",
    javaHashMap : "/hashMap-in-java",
    javaLinkedHashMap : "/linkedhashmap-in-java",
    javaWeakHashMap : "/weakhashmap-in-java",
    javaEnumMap : "/enummap-in-java",
    javaThread : "/threads-in-java",
    javaThread2 : "/threads-in-java-part2",
    javaScriptTutorial : "/javascript-tutorial",
    javaScriptCharacterSet : "/character-set-in-javascript",
    // javaScriptCaseSensitivity : "/case-sensitivity-in-javascript",
    // javaScriptWhitespaces : "/whitespaces-line-breks-format-control-characters-in-java",
    javaScriptUnicodeEscape : "/unicode-escape-sequences-and-normalization-in-javascript",
    // javaScriptNormalization : "/normalization-in-javascript",
    javaScriptUnicodeCharacterSet : "/unicode-character-set-in-javascript",
    javaScriptComments : "/comments-in-javascript",
    javaScriptLiterals : "/literals-in-javascript",
    javaScriptIdentifiersAndReservedWords : "/identifiers-and-reserved-words-in-javascript",
    // javaScriptReservedWords : "/reserved-words-in-javascript",
    // javaScriptSemicolon : "/semicolon-in-javascript",
    javascriptNumbers : "/numbers-in-javascript",
    
    javascriptString : "/string-in-javascript",
    javaScriptReverseString : "/reverse-a-string-in-javascript",
    javaScriptNullAndUndefined : "/null-and-undefined-in-javascript",
    javaScriptGlobalObject : "/global-object-in-javascript",
    javaScriptWrapperObject : "/wrapper-object-in-javascript",
    javaScriptPrimitiveValuesAndObjectReferences : "/primitive-values-and-object-references-in-javascript",
    javaScriptTypeConversion : "/type-conversion-in-javascript",
    javaScriptObjectToPrimitiveConversion : "/object-to-primitive-conversion-in-javascript",

    // javaScriptMathObject : "/math-object-in-javascript",
    // javaScriptDateObject : "/date-and-time-in-javascript",
    // javaScriptDateConversion : "/conversion-of-date-and-time-in-javascript",
    // javaScriptStringMethods : "/string-methods-in-javascript",
    // javaScriptDatatypes : "/data-types-in-javascript",
    // javascriptPrimitiveDatatypes : "/primitive-data-types-in-javascript",
    // javascriptNonPrimitiveDatatypes : "/non-primitive-data-types-in-javascript",
    // javascriptArray : "/array-in-javascript"
    
};

module.exports = {
    ON_DEV,
    AD_SENSE_ACCOUNT,
    AD_SENSE_HORIZONTAL_SLOT,
    AD_SENSE_VERTICAL_SLOT,
    AD_SENSE_ARTICAL_SLOT,
    OPENGRAPH_IMG,
    OPENGRAPH_WIDTH,
    OPENGRAPH_HIGHT,
    URL_INDEX,
    pageurl
}
