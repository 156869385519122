import React, { lazy } from 'react';
import * as PageMetaData from "./PageMetaData";

const About = lazy(() => import('../atrowel/About'));
const Privacy = lazy(() => import('../atrowel/Privacy'));
const Contact = lazy(() => import('../atrowel/Contact'));
const Donate = lazy(() => import('../atrowel/Donate'));
const Home = lazy(() => import('../atrowel/Home'));
const Terms = lazy(() => import('../atrowel/Terms'));
const Cookie = lazy(() => import('../atrowel/Cookie'));
const Disclaimer = lazy(() => import('../atrowel/Disclaimer'));
const JsonLayout = lazy(() => import('../json/fragment/JsonLayout'));
const JavaLayout = lazy(() => import('../java/fragment/JavaLayout'));

const IntroductionToJava = lazy(() => import('../java/IntroductionToJava'));
const JavaEnvironmentSetup = lazy(() => import('../java/JavaEnvironmentSetup'));
const JSONIntro = lazy(() => import('../json/JSONIntro'));
const JSONWriteJava = lazy(() => import('../json/JSONWriteJava'));
const JSONWriteCpp = lazy(() => import('../json/JSONWriteCpp'));
const JSONWriteC = lazy(() => import('../json/JSONWriteC'));
const JSONWritePython = lazy(() => import('../json/JSONWritePython'));
const JSONCompare = lazy(() => import('../json/JSONCompare'));
const JSONMinifier = lazy(() => import('../json/JSONMinifier'));
const JSONToString = lazy(() => import('../json/JSONToString'));
const StringToJSON = lazy(() => import('../json/StringToJSON'));
const JSONToXML = lazy(() => import('../json/JSONToXML'));
const XMLToJSON = lazy(() => import('../json/XMLToJSON'));
const JSONFormatter = lazy(() => import('../json/JSONFormatter'));
const JSONToCSV = lazy(() => import('../json/JSONToCSV'));
const CSVToJSON = lazy(() => import('../json/CSVToJSON'));
const XMLLayout = lazy(() => import('../xml/fragment/XMLLayout'));
const XMLFormatter = lazy(() => import('../xml/XMLFormatter'));
const XMLMinifier = lazy(() => import('../xml/XMLMinifier'));
const XMLCompare = lazy(() => import('../xml/XMLCompare'));
const XMLToEscape = lazy(() => import('../xml/XMLToEscape'));
const UnescapeToXML = lazy(() => import('../xml/UnescapeToXML'));
// const XMLToCSV = lazy(() => import('../xml/XMLToCSV'));
// const XMLToText = lazy(() => import('../xml/XMLToText'));
const ImageLayout = lazy(() => import('../image/fragment/ImageLayout'));
const ImageCrop = lazy(() => import('../image/ImageCrop'));
const ImageResize = lazy(() => import('../image/ImageResize'));
const ImageCompress = lazy(() => import('../image/ImageCompress'));
const ImageConverter = lazy(() => import('../image/ImageConverter'));
const ImageRotate = lazy(() => import('../image/ImageRotate'));
const ImageFlip = lazy(() => import('../image/ImageFlip'));
const FileCompare = lazy(() => import('../file/FileCompare'));
const CharacterCount = lazy(() => import('../charactercount/CharacterCount'));
const CommandLineLayout = lazy(() => import('../commandline/fragment/CommandLineLayout'));
const CommandLineIntroduction = lazy(() => import('../commandline/CommandLineIntroduction'));
const CommandLineTerminal = lazy(() => import('../commandline/CommandLineTerminal'));
const JavaShell = lazy(() => import('../java/JavaShell'));
const JavaDataType = lazy(() => import('../java/JavaDataType'));
const JavaNonPrimitive = lazy(() => import('../java/JavaNonPrimitive'));
const JavaVariables = lazy(() => import('../java/JavaVariables'));
const JavaOperators = lazy(() => import('../java/JavaOperators'));
const JavaBitwiseOperators = lazy(() => import('../java/JavaBitwiseOperators'));
const JavaStringMethod = lazy(() => import('../java/JavaStringMethod'));
const JavaStringMethodPart2 = lazy(() => import('../java/JavaStringMethodPart2'));
const JavaStringBuffer = lazy(() => import('../java/JavaStringBuffer'));
const JavaInputOutput = lazy(() => import('../java/JavaInputOutput'));
const JavaControlFlow = lazy(() => import('../java/JavaControlFlow'));
const JavaArray = lazy(() => import('../java/JavaArray'));
const JavaObjectClass = lazy(() => import('../java/JavaObjectClass'));
const JavaPredefinedClass = lazy(() => import('../java/JavaPredefinedClass'));
const JavaDefinedOwnClass = lazy(() => import('../java/JavaDefinedOwnClass'));
const JavaStaticMethod = lazy(() => import('../java/JavaStaticMethod'));
const JavaLoops = lazy(() => import('../java/JavaLoops'));
const JavaString = lazy(() => import('../java/JavaString'));
const JavaObjectConstruction = lazy(() => import('../java/JavaObjectConstruction'));
const JavaPackages = lazy(() => import('../java/JavaPackages'));
const JavaJAR = lazy(() => import('../java/JavaJAR'));
const JavaInheritance = lazy(() => import('../java/JavaInheritance'));
const JavaInheritanceTypes = lazy(() => import('../java/JavaInheritanceTypes'));
const JavaFinalKeyword = lazy(() => import('../java/JavaFinalKeyword'));
const JavaAbstractClass = lazy(() => import('../java/JavaAbstractClass'));
const JavaObject = lazy(() => import('../java/JavaObject'));
const JavaArrayList = lazy(() => import('../java/JavaArrayList'));
const JavaWrapperClasses = lazy(() => import('../java/JavaWrapperClasses'));
const JavaReflection = lazy(() => import('../java/JavaReflection'));
const JavaReflectionPart2 = lazy(() => import('../java/JavaReflectionPart2'));
const JavaInterface = lazy(() => import('../java/JavaInterface'));
const JavaInterfacePart2 = lazy(() => import('../java/JavaInterfacePart2'));
const JavaLambdaExpressions = lazy(() => import('../java/JavaLambdaExpressions'));
const JavaLambdaExpressionsPart2 = lazy(() => import('../java/JavaLambdaExpressionsPart2'));
const JavaInnerClasses = lazy(() => import('../java/JavaInnerClasses'));
const JavaExceptionHandling = lazy(() => import('../java/JavaExceptionHandling'));
const JavaException = lazy(() => import('../java/JavaException'));
const JavaTypesOfException = lazy(() => import('../java/JavaTypesOfException'));
const JavaAssertions = lazy(() => import('../java/JavaAssertions'));
const JavaCollection = lazy(() => import('../java/JavaCollection'));
const JavaLinkedList = lazy(() => import('../java/JavaLinkedList'));
const JavaHashSet = lazy(() => import('../java/JavaHashSet'));
const JavaTreeSet = lazy(() => import('../java/JavaTreeSet'));
const JavaQueue = lazy(() => import('../java/JavaQueue'));
const JavaDequeue = lazy(() => import('../java/JavaDequeue'));
const JavaArrayDeque = lazy(() => import('../java/JavaArrayDeque'));
const JavaMap = lazy(() => import('../java/JavaMap'));
const JavaTreeMap = lazy(() => import('../java/JavaTreeMap'));
const JavaSortedMap = lazy(() => import('../java/JavaSortedMap'));
const JavaNavigableMap = lazy(() => import('../java/JavaNavigableMap'));
const JavaHashMap = lazy(() => import('../java/JavaHashMap'));
const JavaWeakHashMap = lazy(() => import('../java/JavaWeakHashMap'));
const JavaLinkedHashMap = lazy(() => import('../java/JavaLinkedHashMap'));
const JavaEnumMap = lazy(() => import('../java/JavaEnumMap'));
const JavaThread = lazy(() => import('../java/JavaThread'));
const JavaThreadPart2 = lazy(() => import('../java/JavaThreadPart2'));
const JavaAdvantagesOfLambdaExpression = lazy(() => import('../java/JavaAdvantagesOfLambdaExpression'));

const JavaScriptLayout = lazy(() => import('../rejavascript/fragment/JavaScriptLayout'));
const JavaScriptTutorial = lazy(() => import('../rejavascript/JavaScriptTutorial'));
const JavaScriptChacterSet = lazy(() => import('../rejavascript/JavaScriptCharacterSet'));
// const JavaScriptCaseSensitivity = lazy(() => import('../rejavascript/JavaScriptCaseSensitivity'));
// const JavaScriptWhitespace = lazy(() => import('../rejavascript/JavaScriptWhitespace'));
const JavaScriptUnicodeEscape = lazy(() => import('../rejavascript/JavaScriptUnicodeEscape'));
const JavaScriptNormalization = lazy(() => import('../rejavascript/JavaScriptUnicodeCharacterSet'));
const JavaScriptUnicodeCharacterSet = lazy(() => import('../rejavascript/JavaScriptUnicodeCharacterSet'));
const JavaScriptComments = lazy(() => import('../rejavascript/JavaScriptComments'));
const JavaScriptLiterals = lazy(() => import('../rejavascript/JavaScriptLiterals'));
const JavaScriptIdentifiersAndReservedWords = lazy(() => import('../rejavascript/JavaScriptIdentifiersAndReservedWords'));
// const JavaScriptReservedWords = lazy(() => import('../rejavascript/JavaScriptReservedWords'));
// const JavaScriptSemicolon = lazy(() => import('../rejavascript/JavaScriptSemicolon'));
const JavaScriptNumber = lazy(() => import('../rejavascript/JavaScriptNumber'));
const JavaScriptString = lazy(() => import('../rejavascript/JavaScriptString'));
const JavaScriptReverseString = lazy(() => import('../rejavascript/JavaScriptReverseString'));
const JavaScriptNullAndUndefined = lazy(() => import('../rejavascript/JavaScriptNullAndUndefined'));
const JavaScriptGlobalObject = lazy(() => import('../rejavascript/JavaScriptGlobalObject'));
const JavaScriptWrapperObject = lazy(() => import('../rejavascript/JavaScriptWrapperObject'));
const JavaScriptPrimitiveValuesAndObjectReferences = lazy(() => import('../rejavascript/JavaScriptPrimitiveValuesAndObjectReferences'));
const JavaScriptTypeConversion = lazy(() => import('../rejavascript/JavaScriptTypeConversion'));
const JavaScriptObjectToPrimitiveConversion = lazy(() => import('../rejavascript/JavaScriptObjectToPrimitiveConversion'));

// const JavaScriptDatatypes = lazy(() => import('../rejavascript/JavaScriptDatatypes'));
// const JavaScriptPrimitiveDatatypes = lazy(() => import('../rejavascript/JavaScriptPrimitiveDatatypes'));
// const JavaScriptNonPrimitiveDatatypes = lazy(() => import('../rejavascript/JavaScriptNonPrimitiveDatatypes'));
// const JavaScriptArray = lazy(() => import('../rejavascript/JavaScriptArray'));
// const JavaScriptMathObject = lazy(() => import('../rejavascript/JavaScriptMathObject'));
// const JavaScriptDateObject = lazy(() => import('../rejavascript/JavaScriptDateObject'));
// const JavaScriptDateConversion = lazy(() => import('../rejavascript/JavaScriptDateConversion'));
// const JavaScriptStringMethods = lazy(() => import('../rejavascript/JavaScriptStringMethods'));


export const piElementsMeta = [
  {
    path: PageMetaData.md_home.path,
    element: <Home elemeta={PageMetaData.md_home}></Home>,
  },
  {
    path: PageMetaData.md_about.path,
    element: <About elemeta={PageMetaData.md_about}></About>,
  },
  {
    path: PageMetaData.md_contact.path,
    element: <Contact elemeta={PageMetaData.md_contact}></Contact>,
  },
  {
    path: PageMetaData.md_donate.path,
    element: <Donate elemeta={PageMetaData.md_donate}></Donate>,
  },
  {
    path: PageMetaData.md_privacy.path,
    element: <Privacy elemeta={PageMetaData.md_privacy}></Privacy>,
  },
  {
    path: PageMetaData.md_terms.path,
    element: <Terms elemeta={PageMetaData.md_terms}></Terms>,
  },
  {
    path: PageMetaData.md_cookie.path,
    element: <Cookie elemeta={PageMetaData.md_cookie}></Cookie>,
  },
  {
    path: PageMetaData.md_disclaimer.path,
    element: <Disclaimer elemeta={PageMetaData.md_disclaimer}></Disclaimer>,
  },
  // {
  //   path: PageMetaData.md_sitesetup.path,
  //   element: <SiteSetup elemeta={PageMetaData.md_sitesetup}></SiteSetup>,
  // },
  {
    path: PageMetaData.md_json_intro.path,
    element: (
      <JsonLayout
        element={<JSONIntro></JSONIntro>}
        elemeta={PageMetaData.md_json_intro}
        nextmeta={PageMetaData.md_json_write_java}
        previousmeta={null}
      ></JsonLayout>
    ),
  },
  {
    path: PageMetaData.md_json_write_java.path,
    element: (
      <JsonLayout
        element={<JSONWriteJava></JSONWriteJava>}
        elemeta={PageMetaData.md_json_write_java}
        nextmeta={PageMetaData.md_json_write_cpp}
        previousmeta={PageMetaData.md_json_intro}
      ></JsonLayout>
    ),
  },
  // {
  //   path: PageMetaData.md_json_parse_java.path,
  //   element: (
  //     <JsonLayout
  //       element={<JSONParseJava></JSONParseJava>}
  //       elemeta={PageMetaData.md_json_parse_java}
  //       nextmeta={PageMetaData.md_json_write_cpp}
  //       previousmeta={PageMetaData.md_json_write_java}
  //     ></JsonLayout>
  //   ),
  // },
  {
    path: PageMetaData.md_json_write_cpp.path,
    element: (
      <JsonLayout
        element={<JSONWriteCpp></JSONWriteCpp>}
        elemeta={PageMetaData.md_json_write_cpp}
        nextmeta={PageMetaData.md_json_write_c}
        previousmeta={PageMetaData.md_json_write_java}
      ></JsonLayout>
    ),
  },
  // {
  //   path: PageMetaData.md_json_parse_cpp.path,
  //   element: (
  //     <JsonLayout
  //       element={<JSONParseCpp></JSONParseCpp>}
  //       elemeta={PageMetaData.md_json_parse_cpp}
  //       nextmeta={PageMetaData.md_json_write_c}
  //       previousmeta={PageMetaData.md_json_write_cpp}
  //     ></JsonLayout>
  //   ),
  // },
  {
    path: PageMetaData.md_json_write_c.path,
    element: (
      <JsonLayout
        element={<JSONWriteC></JSONWriteC>}
        elemeta={PageMetaData.md_json_write_c}
        nextmeta={PageMetaData.md_json_write_python}
        previousmeta={PageMetaData.md_json_write_cpp}
      ></JsonLayout>
    ),
  },
  // {
  //   path: PageMetaData.md_json_parse_c.path,
  //   element: (
  //     <JsonLayout
  //       element={<JSONParseC></JSONParseC>}
  //       elemeta={PageMetaData.md_json_parse_c}
  //       nextmeta={PageMetaData.md_json_write_python}
  //       previousmeta={PageMetaData.md_json_write_c}
  //     ></JsonLayout>
  //   ),
  // },
  {
    path: PageMetaData.md_json_write_python.path,
    element: (
      <JsonLayout
        element={<JSONWritePython></JSONWritePython>}
        elemeta={PageMetaData.md_json_write_python}
        nextmeta={PageMetaData.md_json_formatter}
        previousmeta={PageMetaData.md_json_write_c}
      ></JsonLayout>
    ),
  },
  // {
  //   path: PageMetaData.md_json_parse_python.path,
  //   element: (
  //     <JsonLayout
  //       element={<JSONParsePython></JSONParsePython>}
  //       elemeta={PageMetaData.md_json_parse_python}
  //       nextmeta={PageMetaData.md_json_formatter}
  //       previousmeta={PageMetaData.md_json_write_python}
  //     ></JsonLayout>
  //   ),
  // },
  {
    path: PageMetaData.md_json_formatter.path,
    element: (
      <JsonLayout
        element={<JSONFormatter></JSONFormatter>}
        elemeta={PageMetaData.md_json_formatter}
        nextmeta={PageMetaData.md_json_compare}
        previousmeta={PageMetaData.md_json_write_python}
      ></JsonLayout>
    ),
  },
  {
    path: PageMetaData.md_json_compare.path,
    element: (
      <JsonLayout
        element={<JSONCompare></JSONCompare>}
        elemeta={PageMetaData.md_json_compare}
        nextmeta={PageMetaData.md_json_minifier}
        previousmeta={PageMetaData.md_json_formatter}
      ></JsonLayout>
    ),
  },
  {
    path: PageMetaData.md_json_minifier.path,
    element: (
      <JsonLayout
        element={<JSONMinifier></JSONMinifier>}
        elemeta={PageMetaData.md_json_minifier}
        nextmeta={PageMetaData.md_json_to_string}
        previousmeta={PageMetaData.md_json_compare}
      ></JsonLayout>
    ),
  },
  {
    path: PageMetaData.md_json_to_string.path,
    element: (
      <JsonLayout
        element={<JSONToString></JSONToString>}
        elemeta={PageMetaData.md_json_to_string}
        nextmeta={PageMetaData.md_strin_to_json}
        previousmeta={PageMetaData.md_json_minifier}
      ></JsonLayout>
    ),
  },
  {
    path: PageMetaData.md_strin_to_json.path,
    element: (
      <JsonLayout
        element={<StringToJSON></StringToJSON>}
        elemeta={PageMetaData.md_strin_to_json}
        nextmeta={PageMetaData.md_json_to_xml}
        previousmeta={PageMetaData.md_json_to_string}
      ></JsonLayout>
    ),
  },
  {
    path: PageMetaData.md_json_to_xml.path,
    element: (
      <JsonLayout
        element={<JSONToXML></JSONToXML>}
        elemeta={PageMetaData.md_json_to_xml}
        nextmeta={PageMetaData.md_xml_to_json}
        previousmeta={PageMetaData.md_strin_to_json}
      ></JsonLayout>
    ),
  },
  {
    path: PageMetaData.md_xml_to_json.path,
    element: (
      <JsonLayout
        element={<XMLToJSON></XMLToJSON>}
        elemeta={PageMetaData.md_xml_to_json}
        nextmeta={PageMetaData.md_json_to_csv}
        previousmeta={PageMetaData.md_json_to_xml}
      ></JsonLayout>
    ),
  },
  {
    path: PageMetaData.md_json_to_csv.path,
    element: (
      <JsonLayout
        element={<JSONToCSV></JSONToCSV>}
        elemeta={PageMetaData.md_json_to_csv}
        nextmeta={PageMetaData.md_csv_to_json}
        previousmeta={PageMetaData.md_xml_to_json}
      ></JsonLayout>
    ),
  },
  {
    path: PageMetaData.md_csv_to_json.path,
    element: (
      <JsonLayout
        element={<CSVToJSON></CSVToJSON>}
        elemeta={PageMetaData.md_csv_to_json}
        nextmeta={null}
        previousmeta={PageMetaData.md_json_to_csv}
      ></JsonLayout>
    ),
  },
 /*--------------------------------------------------------------
  # Image 
  -------------------------------------------------------------*/
  {
    path: PageMetaData.md_image_crop.path,
    element: (
      <ImageLayout
        element={<ImageCrop></ImageCrop>}
        elemeta={PageMetaData.md_image_crop}
        nextmeta={PageMetaData.md_image_rotate}
        previousmeta={null}
      ></ImageLayout>
    ),
  },
  {
    path: PageMetaData.md_image_rotate.path,
    element: (
      <ImageLayout
        element={<ImageRotate></ImageRotate>}
        elemeta={PageMetaData.md_image_rotate}
        nextmeta={PageMetaData.md_image_flip}
        previousmeta={PageMetaData.md_image_crop}
      ></ImageLayout>
    ),
  },
  {
    path: PageMetaData.md_image_flip.path,
    element: (
      <ImageLayout
        element={<ImageFlip></ImageFlip>}
        elemeta={PageMetaData.md_image_flip}
        nextmeta={PageMetaData.md_image_resize}
        previousmeta={PageMetaData.md_image_rotate}
      ></ImageLayout>
    ),
  },
  {
    path: PageMetaData.md_image_resize.path,
    element: (
      <ImageLayout
        element={<ImageResize></ImageResize>}
        elemeta={PageMetaData.md_image_resize}
        nextmeta={PageMetaData.md_image_compress}
        previousmeta={PageMetaData.md_image_flip}
      ></ImageLayout>
    ),
  },
  {
    path: PageMetaData.md_image_compress.path,
    element: (
      <ImageLayout
        element={<ImageCompress></ImageCompress>}
        elemeta={PageMetaData.md_image_compress}
        nextmeta={PageMetaData.md_image_con_jpg}
        previousmeta={PageMetaData.md_image_resize}
      ></ImageLayout>
    ),
  },
  {
    path: PageMetaData.md_image_con_jpg.path,
    element: (
      <ImageLayout
        element={<ImageConverter convtype="image/jpeg" convtital="JPG"></ImageConverter>}
        elemeta={PageMetaData.md_image_con_jpg}
        nextmeta={PageMetaData.md_image_con_png}
        previousmeta={PageMetaData.md_image_compress}
      ></ImageLayout>
    ),
  },
  {
    path: PageMetaData.md_image_con_png.path,
    element: (
      <ImageLayout
        element={<ImageConverter convtype="image/png" convtital="PNG"></ImageConverter>}
        elemeta={PageMetaData.md_image_con_png}
        nextmeta={PageMetaData.md_image_con_bmp}
        previousmeta={PageMetaData.md_image_con_jpg}
      ></ImageLayout>
    ),
  },
  {
    path: PageMetaData.md_image_con_bmp.path,
    element: (
      <ImageLayout
        element={<ImageConverter convtype="image/bmp" convtital="BMP"></ImageConverter>}
        elemeta={PageMetaData.md_image_con_bmp}
        nextmeta={PageMetaData.md_image_con_webp}
        previousmeta={PageMetaData.md_image_con_png}
      ></ImageLayout>
    ),
  },
  {
    path: PageMetaData.md_image_con_webp.path,
    element: (
      <ImageLayout
        element={<ImageConverter convtype="image/webp" convtital="WEBP"></ImageConverter>}
        elemeta={PageMetaData.md_image_con_webp}
        nextmeta={null}
        previousmeta={PageMetaData.md_image_con_bmp}
      ></ImageLayout>
    ),
  },
  /*--------------------------------------------------------------
  # File 
  -------------------------------------------------------------*/
  {
    path: PageMetaData.md_file_diff_merge.path,
    element: (
     <FileCompare elemeta={PageMetaData.md_file_diff_merge}></FileCompare>
    ),
  },
  /*--------------------------------------------------------------
  # Character Count 
  -------------------------------------------------------------*/
  {
    path: PageMetaData.md_character_counter.path,
    element: (
     <CharacterCount elemeta={PageMetaData.md_character_counter}></CharacterCount>
    ),
  },

  /*--------------------------------------------------------------
  # XML
  -------------------------------------------------------------*/
  {
    path: PageMetaData.md_xml_formatter.path,
    element: (
      <XMLLayout
        element={<XMLFormatter></XMLFormatter>}
        elemeta={PageMetaData.md_xml_formatter}
        nextmeta={PageMetaData.md_xml_minifier}
        previousmeta={null}
      ></XMLLayout>
    ),
  },
  {
    path: PageMetaData.md_xml_minifier.path,
    element: (
      <XMLLayout
        element={<XMLMinifier></XMLMinifier>}
        elemeta={PageMetaData.md_xml_minifier}
        nextmeta={PageMetaData.md_xml_compare}
        previousmeta={PageMetaData.md_xml_formatter}
      ></XMLLayout>
    ),
  },
  {
    path: PageMetaData.md_xml_compare.path,
    element: (
      <XMLLayout
        element={<XMLCompare></XMLCompare>}
        elemeta={PageMetaData.md_xml_compare}
        nextmeta={PageMetaData.md_xml_escape}
        previousmeta={PageMetaData.md_xml_minifier}
      ></XMLLayout>
    ),
  },
  {
    path: PageMetaData.md_xml_escape.path,
    element: (
      <XMLLayout
        element={<XMLToEscape></XMLToEscape>}
        elemeta={PageMetaData.md_xml_escape}
        nextmeta={PageMetaData.md_xml_unescape}
        previousmeta={PageMetaData.md_xml_compare}
      ></XMLLayout>
    ),
  },
  {
    path: PageMetaData.md_xml_unescape.path,
    element: (
      <XMLLayout
        element={<UnescapeToXML></UnescapeToXML>}
        elemeta={PageMetaData.md_xml_unescape}
        nextmeta={null}
        previousmeta={PageMetaData.md_xml_escape}
      ></XMLLayout>
    ),
  },
  // {
  //   path: PageMetaData.md_xml_to_csv.path,
  //   element: (
  //     <XMLLayout
  //       element={<XMLToCSV></XMLToCSV>}
  //       elemeta={PageMetaData.md_xml_to_csv}
  //       nextmeta={PageMetaData.md_xml_to_text}
  //       previousmeta={PageMetaData.md_xml_to_string}
  //     ></XMLLayout>
  //   ),
  // },
  // {
  //   path: PageMetaData.md_xml_to_text.path,
  //   element: (
  //     <XMLLayout
  //       element={<XMLToText></XMLToText>}
  //       elemeta={PageMetaData.md_xml_to_text}
  //       nextmeta={null}
  //       previousmeta={PageMetaData.md_xml_unescape}
  //     ></XMLLayout>
  //   ),
  // },
  /*--------------------------------------------------------------
  # Linux Command Line
  -------------------------------------------------------------*/
  {
    path: PageMetaData.md_commandline_introduction.path,
    element: (
      <CommandLineLayout
        element={<CommandLineIntroduction></CommandLineIntroduction>}
        elemeta={PageMetaData.md_commandline_introduction}
        nextmeta={PageMetaData.md_commandline_terminal}
        previousmeta={null}
      ></CommandLineLayout>
    ),
  },
  {
    path: PageMetaData.md_commandline_terminal.path,
    element: (
      <CommandLineLayout
        element={<CommandLineTerminal></CommandLineTerminal>}
        elemeta={PageMetaData.md_commandline_terminal}
        nextmeta={null}
        previousmeta={PageMetaData.md_commandline_introduction}
      ></CommandLineLayout>
    ),
  },

  /*--------------------------------------------------------------
  # Java 
  -------------------------------------------------------------*/
  {
    path: PageMetaData.md_java_introduction.path,
    element: (
      <JavaLayout
        element={<IntroductionToJava></IntroductionToJava>}
        elemeta={PageMetaData.md_java_introduction}
        nextmeta={PageMetaData.md_java_env_setup}
        previousmeta={null}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_env_setup.path,
    element: (
      <JavaLayout
        element={<JavaEnvironmentSetup></JavaEnvironmentSetup>}
        elemeta={PageMetaData.md_java_env_setup}
        nextmeta={PageMetaData.md_java_jshell}
        previousmeta={PageMetaData.md_java_introduction}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_jshell.path,
    element: (
      <JavaLayout
        element={<JavaShell></JavaShell>}
        elemeta={PageMetaData.md_java_jshell}
        nextmeta={PageMetaData.md_java_datatype}
        previousmeta={PageMetaData.md_java_env_setup}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_datatype.path,
    element: (
      <JavaLayout
        element={<JavaDataType></JavaDataType>}
        elemeta={PageMetaData.md_java_datatype}
        nextmeta={PageMetaData.md_java_nonprimitive}
        previousmeta={PageMetaData.md_java_jshell}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_nonprimitive.path,
    element: (
      <JavaLayout
        element={<JavaNonPrimitive></JavaNonPrimitive>}
        elemeta={PageMetaData.md_java_nonprimitive}
        nextmeta={PageMetaData.md_java_variables}
        previousmeta={PageMetaData.md_java_datatype}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_variables.path,
    element: (
      <JavaLayout
        element={<JavaVariables></JavaVariables>}
        elemeta={PageMetaData.md_java_variables}
        nextmeta={PageMetaData.md_java_operators}
        previousmeta={PageMetaData.md_java_nonprimitive}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_operators.path,
    element: (
      <JavaLayout
        element={<JavaOperators></JavaOperators>}
        elemeta={PageMetaData.md_java_operators}
        nextmeta={PageMetaData.md_java_bitwise_operators}
        previousmeta={PageMetaData.md_java_variables}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_bitwise_operators.path,
    element: (
      <JavaLayout
        element={<JavaBitwiseOperators></JavaBitwiseOperators>}
        elemeta={PageMetaData.md_java_bitwise_operators}
        nextmeta={PageMetaData.md_java_string}
        previousmeta={PageMetaData.md_java_operators}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_string.path,
    element: (
      <JavaLayout
        element={<JavaString></JavaString>}
        elemeta={PageMetaData.md_java_string}
        nextmeta={PageMetaData.md_java_string_method_part1}
        previousmeta={PageMetaData.md_java_bitwise_operators}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_string_method_part1.path,
    element: (
      <JavaLayout
        element={<JavaStringMethod></JavaStringMethod>}
        elemeta={PageMetaData.md_java_string_method_part1}
        nextmeta={PageMetaData.md_java_string_method_part2}
        previousmeta={PageMetaData.md_java_string}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_string_method_part2.path,
    element: (
      <JavaLayout
        element={<JavaStringMethodPart2></JavaStringMethodPart2>}
        elemeta={PageMetaData.md_java_string_method_part2}
        nextmeta={PageMetaData.md_java_string_buffer}
        previousmeta={PageMetaData.md_java_string_method_part1}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_string_buffer.path,
    element: (
      <JavaLayout
        element={<JavaStringBuffer></JavaStringBuffer>}
        elemeta={PageMetaData.md_java_string_buffer}
        nextmeta={PageMetaData.md_java_input_output}
        previousmeta={PageMetaData.md_java_string_method_part2}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_input_output.path,
    element: (
      <JavaLayout
        element={<JavaInputOutput></JavaInputOutput>}
        elemeta={PageMetaData.md_java_input_output}
        nextmeta={PageMetaData.md_java_controle_flow}
        previousmeta={PageMetaData.md_java_string_buffer}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_controle_flow.path,
    element: (
      <JavaLayout
        element={<JavaControlFlow></JavaControlFlow>}
        elemeta={PageMetaData.md_java_controle_flow}
        nextmeta={PageMetaData.md_java_loops}
        previousmeta={PageMetaData.md_java_input_output}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_loops.path,
    element: (
      <JavaLayout
        element={<JavaLoops></JavaLoops>}
        elemeta={PageMetaData.md_java_loops}
        nextmeta={PageMetaData.md_java_array}
        previousmeta={PageMetaData.md_java_controle_flow}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_array.path,
    element: (
      <JavaLayout
        element={<JavaArray></JavaArray>}
        elemeta={PageMetaData.md_java_array}
        nextmeta={PageMetaData.md_java_object_class}
        previousmeta={PageMetaData.md_java_loops}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_object_class.path,
    element: (
      <JavaLayout
        element={<JavaObjectClass></JavaObjectClass>}
        elemeta={PageMetaData.md_java_object_class}
        nextmeta={PageMetaData.md_java_predefined_class}
        previousmeta={PageMetaData.md_java_array}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_predefined_class.path,
    element: (
      <JavaLayout
        element={<JavaPredefinedClass></JavaPredefinedClass>}
        elemeta={PageMetaData.md_java_predefined_class}
        nextmeta={PageMetaData.md_java_defined_own_class}
        previousmeta={PageMetaData.md_java_object_class}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_defined_own_class.path,
    element: (
      <JavaLayout
        element={<JavaDefinedOwnClass></JavaDefinedOwnClass>}
        elemeta={PageMetaData.md_java_defined_own_class}
        nextmeta={PageMetaData.md_java_static_method}
        previousmeta={PageMetaData.md_java_predefined_class}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_static_method.path,
    element: (
      <JavaLayout
        element={<JavaStaticMethod></JavaStaticMethod>}
        elemeta={PageMetaData.md_java_static_method}
        nextmeta={PageMetaData.md_java_object_construction}
        previousmeta={PageMetaData.md_java_defined_own_class}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_object_construction.path,
    element: (
      <JavaLayout
        element={<JavaObjectConstruction></JavaObjectConstruction>}
        elemeta={PageMetaData.md_java_object_construction}
        nextmeta={PageMetaData.md_java_packages}
        previousmeta={PageMetaData.md_java_static_method}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_packages.path,
    element: (
      <JavaLayout
        element={<JavaPackages></JavaPackages>}
        elemeta={PageMetaData.md_java_packages}
        nextmeta={PageMetaData.md_java_jar}
        previousmeta={PageMetaData.md_java_object_construction}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_jar.path,
    element: (
      <JavaLayout
        element={<JavaJAR></JavaJAR>}
        elemeta={PageMetaData.md_java_jar}
        nextmeta={PageMetaData.md_java_inheritance}
        previousmeta={PageMetaData.md_java_packages}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_inheritance.path,
    element: (
      <JavaLayout
        element={<JavaInheritance></JavaInheritance>}
        elemeta={PageMetaData.md_java_inheritance}
        nextmeta={PageMetaData.md_java_inheritance_types}
        previousmeta={PageMetaData.md_java_jar}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_inheritance_types.path,
    element: (
      <JavaLayout
        element={<JavaInheritanceTypes></JavaInheritanceTypes>}
        elemeta={PageMetaData.md_java_inheritance_types}
        nextmeta={PageMetaData.md_java_final_keyword}
        previousmeta={PageMetaData.md_java_inheritance}    
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_final_keyword.path,
    element: (
      <JavaLayout
        element={<JavaFinalKeyword></JavaFinalKeyword>}
        elemeta={PageMetaData.md_java_final_keyword}
        nextmeta={PageMetaData.md_java_abstract_keyword}
        previousmeta={PageMetaData.md_java_inheritance_types}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_abstract_keyword.path,
    element: (
      <JavaLayout
        element={<JavaAbstractClass></JavaAbstractClass>}
        elemeta={PageMetaData.md_java_abstract_keyword}
        nextmeta={PageMetaData.md_java_object}
        previousmeta={PageMetaData.md_java_final_keyword}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_object.path,
    element: (
      <JavaLayout
        element={<JavaObject></JavaObject>}
        elemeta={PageMetaData.md_java_abstract_keyword}
        nextmeta={PageMetaData.md_java_arraylist}
        previousmeta={PageMetaData.md_java_abstract_keyword}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_arraylist.path,
    element: (
      <JavaLayout
        element={<JavaArrayList></JavaArrayList>}
        elemeta={PageMetaData.md_java_arraylist}
        nextmeta={PageMetaData.md_java_wrapper_class}
        previousmeta={PageMetaData.md_java_object}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_wrapper_class.path,
    element: (
      <JavaLayout
        element={<JavaWrapperClasses></JavaWrapperClasses>}
        elemeta={PageMetaData.md_java_wrapper_class}
        nextmeta={PageMetaData.md_java_reflection}
        previousmeta={PageMetaData.md_java_arraylist}
      ></JavaLayout>
    ),
  }, 
  {
    path: PageMetaData.md_java_reflection.path,
    element: (
      <JavaLayout
        element={<JavaReflection></JavaReflection>}
        elemeta={PageMetaData.md_java_reflection}
        nextmeta={PageMetaData.md_java_reflection_part2}
        previousmeta={PageMetaData.md_java_wrapper_class}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData.md_java_reflection_part2.path,
    element: (
      <JavaLayout
        element={<JavaReflectionPart2></JavaReflectionPart2>}
        elemeta={PageMetaData.md_java_reflection_part2}
        nextmeta={PageMetaData.md_java_interaface}
        previousmeta={PageMetaData.md_java_reflection}
      ></JavaLayout>
    ),
  },
  {
    path: PageMetaData. md_java_interaface.path,
      element: (
        <JavaLayout
          element={<JavaInterface></JavaInterface>}
          elemeta={PageMetaData.md_java_interaface}
          nextmeta={PageMetaData.md_java_interaface_part_2}
          previousmeta={PageMetaData.md_java_reflection_part2}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData. md_java_interaface_part_2.path,
      element: (
        <JavaLayout
          element={<JavaInterfacePart2></JavaInterfacePart2>}
          elemeta={PageMetaData.md_java_interaface_part_2}
          nextmeta={PageMetaData.md_java_lambda_expressions}
          previousmeta={PageMetaData.md_java_interaface}
        ></JavaLayout>
      ),
  },
   {
    path: PageMetaData.md_java_lambda_expressions.path,
      element: (
        <JavaLayout
          element={<JavaLambdaExpressions></JavaLambdaExpressions>}
          elemeta={PageMetaData.md_java_lambda_expressions}
          nextmeta={PageMetaData.md_java_lambda_expressions_part_2}
          previousmeta={PageMetaData.md_java_interaface_part_2}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_lambda_expressions_part_2.path,
      element: (
        <JavaLayout
          element={<JavaLambdaExpressionsPart2></JavaLambdaExpressionsPart2>}
          elemeta={PageMetaData.md_java_lambda_expressions_part_2}
          nextmeta={PageMetaData.md_java_advantages_of_lambda_expression}
          previousmeta={PageMetaData.md_java_lambda_expressions}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_advantages_of_lambda_expression.path,
      element: (
        <JavaLayout
          element={<JavaAdvantagesOfLambdaExpression></JavaAdvantagesOfLambdaExpression>}
          elemeta={PageMetaData.md_java_advantages_of_lambda_expression}
          nextmeta={PageMetaData.md_java_inner_classes}
          previousmeta={PageMetaData.md_java_lambda_expressions_part_2}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_inner_classes.path,
      element: (
        <JavaLayout
          element={<JavaInnerClasses></JavaInnerClasses>}
          elemeta={PageMetaData.md_java_inner_classes}
          nextmeta={PageMetaData.md_java_exception}
          previousmeta={PageMetaData.md_java_lambda_expressions_part_2}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_exception.path,
      element: (
        <JavaLayout
          element={<JavaException></JavaException>}
          elemeta={PageMetaData.md_java_exception}
          nextmeta={PageMetaData.md_java_exception_handling}
          previousmeta={PageMetaData.md_java_inner_classes}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_exception_handling.path,
      element: (
        <JavaLayout
          element={<JavaExceptionHandling></JavaExceptionHandling>}
          elemeta={PageMetaData.md_java_exception_handling}
          nextmeta={PageMetaData.md_java_types_of_exceptions}
          previousmeta={PageMetaData.md_java_exception}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_types_of_exceptions.path,
      element: (
        <JavaLayout
          element={<JavaTypesOfException></JavaTypesOfException>}
          elemeta={PageMetaData.md_java_types_of_exceptions}
          nextmeta={PageMetaData.md_java_assertion}
          previousmeta={PageMetaData.md_java_exception_handling}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_assertion.path,
      element: (
        <JavaLayout
          element={<JavaAssertions></JavaAssertions>}
          elemeta={PageMetaData.md_java_assertion}
          nextmeta={PageMetaData.md_java_collection}
          previousmeta={PageMetaData.md_java_types_of_exceptions}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_collection.path,
      element: (
        <JavaLayout
          element={<JavaCollection></JavaCollection>}
          elemeta={PageMetaData.md_java_collection}
          nextmeta={PageMetaData.md_java_linked_list}
          previousmeta={PageMetaData.md_java_assertion}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_linked_list.path,
      element: (
        <JavaLayout
          element={<JavaLinkedList></JavaLinkedList>}
          elemeta={PageMetaData.md_java_linked_list}
          nextmeta={PageMetaData.md_java_hashset}
          previousmeta={PageMetaData.md_java_collection}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_hashset.path,
      element: (
        <JavaLayout
          element={<JavaHashSet></JavaHashSet>}
          elemeta={PageMetaData.md_java_hashset}
          nextmeta={PageMetaData.md_java_treeset}
          previousmeta={PageMetaData.md_java_linked_list}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_treeset.path,
      element: (
        <JavaLayout
          element={<JavaTreeSet></JavaTreeSet>}
          elemeta={PageMetaData.md_java_treeset}
          nextmeta={PageMetaData.md_java_queue}
          previousmeta={PageMetaData.md_java_hashset}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_queue.path,
      element: (
        <JavaLayout
          element={<JavaQueue></JavaQueue>}
          elemeta={PageMetaData.md_java_queue}
          nextmeta={null}
          previousmeta={PageMetaData.md_java_treeset}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_dequeue.path,
      element: (
        <JavaLayout
          element={<JavaDequeue></JavaDequeue>}
          elemeta={PageMetaData.md_java_dequeue}
          nextmeta={PageMetaData.md_java_arraydeque}
          previousmeta={PageMetaData.md_java_queue}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_arraydeque.path,
      element: (
        <JavaLayout
          element={<JavaArrayDeque></JavaArrayDeque>}
          elemeta={PageMetaData.md_java_arraydeque}
          nextmeta={PageMetaData.md_java_map}
          previousmeta={PageMetaData.md_java_dequeue}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_map.path,
      element: (
        <JavaLayout
          element={<JavaMap></JavaMap>}
          elemeta={PageMetaData.md_java_map}
          nextmeta={PageMetaData.md_java_treemap}
          previousmeta={PageMetaData.md_java_arraydeque}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_treemap.path,
      element: (
        <JavaLayout
          element={<JavaTreeMap></JavaTreeMap>}
          elemeta={PageMetaData.md_java_treemap}
          nextmeta={null}
          previousmeta={PageMetaData.md_java_map}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_treemap.path,
      element: (
        <JavaLayout
          element={<JavaTreeMap></JavaTreeMap>}
          elemeta={PageMetaData.md_java_treemap}
          nextmeta={PageMetaData.md_java_sortedmap}
          previousmeta={PageMetaData.md_java_map}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_sortedmap.path,
      element: (
        <JavaLayout
          element={<JavaSortedMap></JavaSortedMap>}
          elemeta={PageMetaData.md_java_sortedmap}
          nextmeta={PageMetaData.md_java_navigablemap}
          previousmeta={PageMetaData.md_java_treemap}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_navigablemap.path,
      element: (
        <JavaLayout
          element={<JavaNavigableMap></JavaNavigableMap>}
          elemeta={PageMetaData.md_java_navigablemap}
          nextmeta={PageMetaData.md_java_hashmap}
          previousmeta={PageMetaData.md_java_sortedmap}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_hashmap.path,
      element: (
        <JavaLayout
          element={<JavaHashMap></JavaHashMap>}
          elemeta={PageMetaData.md_java_hashmap}
          nextmeta={PageMetaData.md_java_linkedhashmap}
          previousmeta={PageMetaData.md_java_navigablemap}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_linkedhashmap.path,
      element: (
        <JavaLayout
          element={<JavaLinkedHashMap></JavaLinkedHashMap>}
          elemeta={PageMetaData.md_java_linkedhashmap}
          nextmeta={PageMetaData.md_java_weakhashmap}
          previousmeta={PageMetaData.md_java_hashmap}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_weakhashmap.path,
      element: (
        <JavaLayout
          element={<JavaWeakHashMap></JavaWeakHashMap>}
          elemeta={PageMetaData.md_java_weakhashmap}
          nextmeta={PageMetaData.md_java_enummap}
          previousmeta={PageMetaData.md_java_linkedhashmap}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_enummap.path,
      element: (
        <JavaLayout
          element={<JavaEnumMap></JavaEnumMap>}
          elemeta={PageMetaData.md_java_enummap}
          nextmeta={PageMetaData.md_java_thread}
          previousmeta={PageMetaData.md_java_weakhashmap}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_thread.path,
      element: (
        <JavaLayout
          element={<JavaThread></JavaThread>}
          elemeta={PageMetaData.md_java_thread}
          nextmeta={PageMetaData.md_java_thread2}
          previousmeta={PageMetaData.md_java_enummap}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_java_thread2.path,
      element: (
        <JavaLayout
          element={<JavaThreadPart2></JavaThreadPart2>}
          elemeta={PageMetaData.md_java_thread2}
          nextmeta={PageMetaData.md_java_advantages_of_lambda_expression}
          previousmeta={PageMetaData.md_java_thread}
        ></JavaLayout>
      ),
  },
  {
    path: PageMetaData.md_javascript_tutorial.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptTutorial></JavaScriptTutorial>}
          elemeta={PageMetaData.md_javascript_tutorial}
          nextmeta={PageMetaData.md_character_set}
          previousmeta={null}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_character_set.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptChacterSet></JavaScriptChacterSet>}
          elemeta={PageMetaData.md_character_set}
          nextmeta={PageMetaData.md_unicode_escape}
          previousmeta={PageMetaData.md_javascript_tutorial}
        ></JavaScriptLayout>
      ),
  },
  // {
  //   path: PageMetaData.md_case_sentivity.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptCaseSensitivity></JavaScriptCaseSensitivity>}
  //         elemeta={PageMetaData.md_case_sentivity}
  //         nextmeta={PageMetaData.md_whitespace}
  //         previousmeta={PageMetaData.md_character_set}
  //       ></JavaScriptLayout>
  //     ),
  // },
  // {
  //   path: PageMetaData.md_whitespace.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptWhitespace></JavaScriptWhitespace>}
  //         elemeta={PageMetaData.md_whitespace}
  //         nextmeta={PageMetaData.md_unicode_escape}
  //         previousmeta={PageMetaData.md_case_sentivity}
  //       ></JavaScriptLayout>
  //     ),
  // },
  {
    path: PageMetaData.md_unicode_escape.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptUnicodeEscape></JavaScriptUnicodeEscape>}
          elemeta={PageMetaData.md_unicode_escape}
          nextmeta={PageMetaData.md_unicodecharacterset}
          previousmeta={PageMetaData.md_character_set}
        ></JavaScriptLayout>
      ),
  },
  // {
  //   path: PageMetaData.md_normalization.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptNormalization></JavaScriptNormalization>}
  //         elemeta={PageMetaData.md_normalization}
  //         nextmeta={PageMetaData.md_comments}
  //         previousmeta={PageMetaData.md_unicode_escape}
  //       ></JavaScriptLayout>
  //     ),
  // },
  {
    path: PageMetaData.md_unicodecharacterset.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptUnicodeCharacterSet></JavaScriptUnicodeCharacterSet>}
          elemeta={PageMetaData.md_unicodecharacterset}
          nextmeta={PageMetaData.md_comments}
          previousmeta={PageMetaData.md_unicode_escape}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_comments.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptComments></JavaScriptComments>}
          elemeta={PageMetaData.md_comments}
          nextmeta={PageMetaData.md_literals}
          previousmeta={PageMetaData.md_unicodecharacterset}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_literals.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptLiterals></JavaScriptLiterals>}
          elemeta={PageMetaData.md_literals}
          nextmeta={PageMetaData.md_identifersandreservedwords}
          previousmeta={PageMetaData.md_comments}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_identifersandreservedwords.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptIdentifiersAndReservedWords></JavaScriptIdentifiersAndReservedWords>}
          elemeta={PageMetaData.md_identifersandreservedwords}
          nextmeta={PageMetaData.md_numbers}
          previousmeta={PageMetaData.md_literals}
        ></JavaScriptLayout>
      ),
  },
  // {
  //   path: PageMetaData.md_reservedwords.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptReservedWords></JavaScriptReservedWords>}
  //         elemeta={PageMetaData.md_reservedwords}
  //         nextmeta={PageMetaData.md_semicolon}
  //         previousmeta={PageMetaData.md_identifersandreservedwords}
  //       ></JavaScriptLayout>
  //     ),
  // },
  // {
  //   path: PageMetaData.md_semicolon.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptSemicolon></JavaScriptSemicolon>}
  //         elemeta={PageMetaData.md_semicolon}
  //         nextmeta={PageMetaData.md_numbers}
  //         previousmeta={PageMetaData.md_identifersandreservedwords}
  //       ></JavaScriptLayout>
  //     ),
  // },
  {
    path: PageMetaData.md_numbers.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptNumber></JavaScriptNumber>}
          elemeta={PageMetaData.md_numbers}
          nextmeta={PageMetaData.md_string}
          previousmeta={PageMetaData.md_identifersandreservedwords}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_string.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptString></JavaScriptString>}
          elemeta={PageMetaData.md_string}
          nextmeta={PageMetaData.md_reversestring}
          previousmeta={PageMetaData.md_numbers}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_reversestring.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptReverseString></JavaScriptReverseString>}
          elemeta={PageMetaData.md_reversestring}
          nextmeta={PageMetaData.md_nullandundefined}
          previousmeta={PageMetaData.md_string}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_nullandundefined.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptNullAndUndefined></JavaScriptNullAndUndefined>}
          elemeta={PageMetaData.md_nullandundefined}
          nextmeta={PageMetaData.md_globalobject}
          previousmeta={PageMetaData.md_reversestring}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_globalobject.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptGlobalObject></JavaScriptGlobalObject>}
          elemeta={PageMetaData.md_globalobject}
          nextmeta={PageMetaData.md_wrapperobject}
          previousmeta={PageMetaData.md_nullandundefined}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_wrapperobject.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptWrapperObject></JavaScriptWrapperObject>}
          elemeta={PageMetaData.md_wrapperobject}
          nextmeta={PageMetaData.md_primitivevaluesandobjectreferences}
          previousmeta={PageMetaData.md_globalobject}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_primitivevaluesandobjectreferences.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptPrimitiveValuesAndObjectReferences></JavaScriptPrimitiveValuesAndObjectReferences>}
          elemeta={PageMetaData.md_primitivevaluesandobjectreferences}
          nextmeta={PageMetaData.md_typeconversion}
          previousmeta={PageMetaData.md_wrapperobject}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_typeconversion.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptTypeConversion></JavaScriptTypeConversion>}
          elemeta={PageMetaData.md_typeconversion}
          nextmeta={PageMetaData.md_objecttoprimitiveconversion}
          previousmeta={PageMetaData.md_primitivevaluesandobjectreferences}
        ></JavaScriptLayout>
      ),
  },
  {
    path: PageMetaData.md_objecttoprimitiveconversion.path,
      element: (
        <JavaScriptLayout
          element={<JavaScriptObjectToPrimitiveConversion></JavaScriptObjectToPrimitiveConversion>}
          elemeta={PageMetaData.md_objecttoprimitiveconversion}
          nextmeta={null}
          previousmeta={PageMetaData.md_typeconversion}
        ></JavaScriptLayout>
      ),
  }
  
  // {
  //   path: PageMetaData.md_mathobject.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptMathObject></JavaScriptMathObject>}
  //         elemeta={PageMetaData.md_mathobject}
  //         nextmeta={PageMetaData.md_dateobject}
  //         previousmeta={PageMetaData.md_numbers}
  //       ></JavaScriptLayout>
  //     ),
  // },
  // {
  //   path: PageMetaData.md_dateobject.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptDateObject></JavaScriptDateObject>}
  //         elemeta={PageMetaData.md_dateobject}
  //         nextmeta={PageMetaData.md_dateconversion}
  //         previousmeta={PageMetaData.md_mathobject}
  //       ></JavaScriptLayout>
  //     ),
  // },
  // {
  //   path: PageMetaData.md_dateconversion.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptDateConversion></JavaScriptDateConversion>}
  //         elemeta={PageMetaData.md_dateconversion}
  //         nextmeta={PageMetaData.md_stringmethods}
  //         previousmeta={PageMetaData.md_dateobject}
  //       ></JavaScriptLayout>
  //     ),
  // },
  // {
  //   path: PageMetaData.md_stringmethods.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptStringMethods></JavaScriptStringMethods>}
  //         elemeta={PageMetaData.md_stringmethods}
  //         nextmeta={PageMetaData.md_reversestring}
  //         previousmeta={PageMetaData.md_dateconversion}
  //       ></JavaScriptLayout>
  //     ),
  // },
  // {
  //   path: PageMetaData.md_datatypes.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptDatatypes></JavaScriptDatatypes>}
  //         elemeta={PageMetaData.md_datatypes}
  //         nextmeta={PageMetaData.md_primitivedatatypes}
  //         previousmeta={PageMetaData.md_reversestring}
  //       ></JavaScriptLayout>
  //     ),
  // },
  // {
  //   path: PageMetaData.md_primitivedatatypes.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptPrimitiveDatatypes></JavaScriptPrimitiveDatatypes>}
  //         elemeta={PageMetaData.md_primitivedatatypes}
  //         nextmeta={PageMetaData.md_nonprimitivedatatypes}
  //         previousmeta={PageMetaData.md_datatypes}
  //       ></JavaScriptLayout>
  //     ),
  // },
  // {
  //   path: PageMetaData.md_nonprimitivedatatypes.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptNonPrimitiveDatatypes></JavaScriptNonPrimitiveDatatypes>}
  //         elemeta={PageMetaData.md_nonprimitivedatatypes}
  //         nextmeta={PageMetaData.md_array}
  //         previousmeta={PageMetaData.md_primitivedatatypes}
  //       ></JavaScriptLayout>
  //     ),
  // },
  // {
  //   path: PageMetaData.md_array.path,
  //     element: (
  //       <JavaScriptLayout
  //         element={<JavaScriptArray></JavaScriptArray>}
  //         elemeta={PageMetaData.md_array}
  //         nextmeta={null}
  //         previousmeta={PageMetaData.md_nonprimitivedatatypes}
  //       ></JavaScriptLayout>
  //     ),
  // },
];
